<template>
  <div class="login">
    <div class="navTitle"><img src="@/assets/img/arrl.png" alt="" @click="goBack">登录</div>
    <img class="bg" src="../../assets/img/loginbg.png" alt="">
    
    <div class="login-card">
        <div class="catalog">
            <div :class="[{ blue: loginInfo.visible ==  '1'}, 'div']" @click="changeLogin(1)">用户名登录</div>
            <div :class="[{ blue: loginInfo.visible ==  '2'}, 'div']" @click="changeLogin(0)">企业名登录</div>
        </div>
        <div class="catalog">
            <img src="../../assets/img/login_icon1.png" alt="">
            <input type="text" :placeholder="loginInfo.visible == 1?'用户名':'企业名称'" v-model="loginInfo.userName">
        </div>
        <div class="catalog">
            <img src="../../assets/img/login_icon2.png" alt="">
            <input type="password" placeholder="输入密码" v-model="loginInfo.password">
        </div>
        <div class="catalog">
            <img src="../../assets/img/login_icon3.png" alt="">
            <input style="width: 50%" type="text" placeholder="输入验证码" v-model="inputCode">
            <VerificationCode class="verify" ref="verify" @verificationCodeEmit="verificationCodeEmit"></VerificationCode>
        </div>
        <div class="agree">
        <van-checkbox v-model="checked" shape="square" icon-size="3.5vw">&nbsp;</van-checkbox>
        <p>我已阅读并同意<span class="blue" @click="openService">《用户服务协议》</span>和<span class="blue" @click="openPrivacy">《隐私政策》</span></p>      
        
        </div>
        <button class="login-btn" @click="login">登录</button>
        <div class="login-footer flex-between">
            <router-link to="/logins" replace class="gray">现在注册</router-link>
            <router-link style="display:none" :to="{path: '/passwordForget', query: { type: loginInfo.visible }}" replace class="gray">忘记密码</router-link>
        </div>

    </div>
        <!-- 服务协议 -->
    <van-popup round v-model:show="showService">
        <div class="pop">
            <div class="pop-content">
                <iframe src="http://yhapp.gaoliuxu.com/serviceAgreement.html" width="100%" height="98%" frameborder="0"></iframe>
            </div>
            <div class="pop-footer">
                <div class="back" @click="closePop">确认</div>
            </div>
        </div>
    </van-popup>
        <!-- 隐私政策 -->
    <van-popup round v-model:show="showPrivacy">
        <div class="pop">
            <div class="pop-content">
                <iframe src="http://yhapp.gaoliuxu.com/policy.html" width="100%" height="98%" frameborder="0"></iframe>
            </div>
            <div class="pop-footer">
                <div class="back" @click="closePop">确认</div>
            </div>
        </div>
    </van-popup>
  </div>
</template>
<script>
import { ref,reactive,toRaw} from 'vue';
import { Toast,Dialog  } from 'vant';
import { useStore } from 'vuex';
import {useRouter} from 'vue-router';
import { apiLogin } from '../../api/axios';
import VerificationCode from '../../components/VerificationCode.vue'
export default {
  setup() {
    const loginInfo = reactive({userName: '',password: '',visible: '1',registrationId: 'webtest01'});
    window.uploadRegisterId = (str)=>{
      loginInfo.registrationId = str;
    };
    try {
      window.scanJsAgent.uploadRisId();
    } catch (e) {
      console.log('获取手机id失败');
    }
    let store = useStore();
    let router = useRouter();
    const verify = ref(null);
    let verificationCode = null;
    function verificationCodeEmit (code) {
        // console.log('父组件接收到的验证码',code);
      verificationCode = code;
    }
    const checked = ref(false);
    const inputCode = ref('');
    const changeLogin = (i) => {
        if(i) {
            loginInfo.visible = '1'
        }else {
            loginInfo.visible = '2'
        }
    }
    const showService = ref(false);
    const openService= () => {
        document.body.scrollTop = 1;
        document.documentElement.scrollTop = 1;
        // 兼容IE
        window.scrollTo(0,1);
        showService.value = true;
    };
    const showPrivacy = ref(false);
    const openPrivacy= () => {
        document.body.scrollTop = 1;
        document.documentElement.scrollTop = 1;
        // 兼容IE
        window.scrollTo(0,1);
        showPrivacy.value = true;
    };
    const closePop= () => {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
        // 兼容IE
        window.scrollTo(0, 0);
        showService.value = false;
        showPrivacy.value = false;
    };
    const login= () => {
        if(loginInfo.registrationId.length<1) {
            Toast('请使用桂信融APP登录');
            return;
        }
        if(!checked.value) {
            Toast('请先阅读用户服务协议');
            return
        }
        if(inputCode.value.toLowerCase() == verificationCode.toLowerCase()) {
            apiLogin(toRaw(loginInfo)).then(res => {
            // 获取数据成功后的其他操作
           switch(res.code) {
                case '6':                                   
                    if (typeof(Storage) !== "undefined") {
                            Toast.success('登录成功');
                            let storage = res.info;
                            storage.userName = loginInfo.userName; 
                            //储存/更新token到本地localStorage及store
                            localStorage.setItem("gxrToken", JSON.stringify(storage));
                            store.dispatch('setToken',storage);
                            router.go(-1);
                        } else {
                            let storage = res.info;
                            // storage.userName = loginInfo.userName; 
                            console.log("抱歉！您的浏览器不支持 Web Storage ...");
                            store.dispatch('setToken',storage);
                            router.go(-1);
                        }                
                    break;
                case '0':
                    Toast('缺失参数或参数为空');
                    break;
                case '1':
                    Toast('用户名/企业名称或密码错误次数超过限制，请10分钟后再尝试');
                    break;
                case '2':
                    Toast('用户名/企业名称或密码有误');
                    break;
                case '3':
                    Toast('该账号已失效');
                    break;
                case '4':
                    Dialog.confirm({
                        title: '提示！',
                        confirmButtonText: '修改密码',
                        message:'距离上次修改密码已超过规定时间，请重新修改密码再登录。',
                        })
                        .then(() => {
                            router.push({path: '/passwordChange',query: {name: loginInfo.userName}});
                        })
                        .catch(() => {
                            console.log('取消修改密码!')
                        });
                    break;
                case '5':
                    Toast('不是企业用户或政府用户');
                    break;
                default:
                    Toast('其他错误，请联系我们');
            }             
            verify.value.showNum();
            })
        }else {
            Toast('验证码输入错误');
            // verify.value.showNum();
            return
        }

    };

    return {
        verify,
        loginInfo,
        verificationCodeEmit,
        changeLogin,
        checked,
        inputCode,
        showService,
        openService,
        showPrivacy,
        openPrivacy,
        closePop,
        login
    }
  },
  methods:{
    goBack(){
        this.$router.go(-1);
    },
  },
  components: {
    VerificationCode
  }
}
</script>
<style lang="less" scoped>
.login {
    min-height: calc(100vh + 1px);
    .navTitle {
        background: none;
    }
    img.bg {
        width: 100%;
        height: 59.4vw;
    }
    .login-card {
        margin: 0 14vw;
        margin-top: 10vw;
        .catalog {
            display: flex;
            align-items: center;
            border-bottom: 1px solid rgba(0,0,0,.05);
            padding: 2vw 0;
            .div {
                width: 50%;
                text-align: center;
                font-size: 4vw;
                color: #666;
                padding: 3vw 0;
                border-bottom: 1px solid rgba(0,0,0,.05);
            }
            .blue {
                color: #65b8fc;
                border-bottom: 1px solid #65b8fc;
            }
            img {
                width: 5vw;
                height: auto;
            }
            input {
                background: transparent;
                border: 0;
                margin-left: 2vw;
                width: 61vw;
                border: 0;
                padding: 2vw;
                font-size: 4vw;
                border-radius: 1vw;
            }
            .verify {
                width: 25vw;
                height: 8vw;
            }
            
        }
        .catalog:first-child {
            padding: 0;
        }
        .login-btn {
            background: linear-gradient(to right, #ff9900, #ff6700);
            color: #fff;
            font-size: 4.5vw;
            padding: 3vw 6vw;
            border-radius: 7vw;
            border: 0;
            margin-top: 8vw;
            width: 100%;
            letter-spacing: 2vw;
        }
        .agree {
            margin-top: 4vw;
            font-size: 3.5vw;
            color: #666;
            display: flex;
            align-items: baseline;
           
        }
    
    }
    .blue {
            color: #3399ff
        }
        .gray {
            color: #666666;
        }
    .login-footer {
        margin-top: 4vw;        
        font-size: 4vw;
        
    }
    .pop {
        padding-bottom: 4vw;
        width: 85vw;
        .pop-content {
            width: 100%;
            height: 60vh;
            font-size: 4vw;
            overflow-y: auto;
            line-height: 6vw;
            letter-spacing: 1px;
        }
        .pop-footer {
            text-align: center;padding-top: 4vw;
            .back {
                display: inline-block;
                background: linear-gradient(to right, #ff9900, #ff6700);
                color: #fff;
                font-size: 4vw;
                padding: 1.5vw 0;
                border-radius: 5vw;
                width: 22vw;
            }
        }
    }
    
}
</style>
